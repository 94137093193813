@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");
@import '@fortawesome/fontawesome-free/css/all.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
    color: #333;
    fill: #333;
    font-family: Source Sans Pro, sans-serif;
}
